<template>
  <div>
    <button
      type="button"
      class="px-5 py-0.5 mt-8 block tg-mobile-button-small lg:tg-desktop-body text-white bg-brand-primary rounded-full shadow-card hover:bg-opacity-84"
      @click="showSlotForm"
    >
      <i class="far fa-plus mr-2" /> {{ $t('add_reservation_block') }}
    </button>
    <transition name="fade" mode="out-in">
      <div
        v-if="slotForm"
        class="w-full h-full fixed inset-0 z-40 grid place-items-center bg-overlay overflow-auto"
      >
        <div class="w-full max-w-lg bg-white rounded-lg cursor-auto">
          <div class="flex justify-between p-5 border-b border-gray-e9">
            <h4
              class="tg-mobile-header-4 lg:tg-desktop-header-4"
              v-text="$t('add_reservation_block')"
            />
            <button type="button" class="-m-4" @click="hideSlotForm">
              <i class="p-4 far fa-times" />
            </button>
          </div>

          <FormulateForm
            #default="{ isLoading, hasErrors }"
            v-model="slotData"
            name="propertyCreateSlots"
            class="px-5"
            @submit="submit"
          >
            <div class="max-w-xs grid grid-cols-7 gap-5">
              <FormulateInput
                type="timepicker"
                name="datetime_start"
                :label="$t('label_datetime_start')"
                :hours="hours()"
                :minutes="minutes()"
                :show-required-label="true"
                validation="bail|required|validTime|fiveMinDifference"
                :validation-rules="{ validTime, fiveMinDifference }"
                :validation-messages="{
                  validTime: $t('valid_time'),
                  fiveMinDifference: $t('message_five_min_difference')
                }"
                outer-class="mt-5 col-span-3"
              />
              <i class="mx-auto mt-14 far fa-arrow-right text-xs col-span-1" />
              <FormulateInput
                type="timepicker"
                name="datetime_end"
                :label="$t('label_datetime_end')"
                :hours="hours()"
                :minutes="minutes()"
                :show-required-label="true"
                validation="bail|required|validTime|fiveMinDifference"
                :validation-rules="{ validTime, fiveMinDifference }"
                :validation-messages="{
                  validTime: $t('valid_time'),
                  fiveMinDifference: $t('message_five_min_difference')
                }"
                outer-class="mt-5 col-span-3"
              />
            </div>
            <FormulateInput
              v-model.number="max_bookings"
              type="text"
              inputmode="numeric"
              name="max_bookings"
              value="1"
              :label="$t('max_bookings')"
              :show-required-label="true"
              validation="bail|required|number|min:1,value"
              :validation-name="$t('this_field')"
            />
            <transition name="fade" mode="out-in">
              <FormulateInput
                v-if="intervals.length"
                key="interval_input"
                type="select"
                name="interval"
                :label="$t('label_interval')"
                :placeholder="$t('placeholder_select_interval')"
                :options="intervals"
              />
            </transition>

            <transition-group name="fade" mode="out-in">
              <template v-if="slotData.interval">
                <p
                  key="intervalSlotsInfo"
                  class="tg-mobile-body-small md:tg-desktop-body"
                >
                  {{ $t('there_are') }}
                  <span class="text-brand-primary font-semibold">
                    {{ slots.length }} {{ $t('appointment_blocks') }}
                  </span>
                  {{ $t('from') }}
                  <span class="text-brand-primary font-semibold">
                    {{ slotData.interval / 60000 }} {{ $t('minutes') }}
                  </span>
                  {{ $t('created') }}.
                </p>
                <details key="intervalSlots">
                  <summary
                    class="link font-semibold"
                    v-text="$t('see_the_slots_to_be_created')"
                  />
                  <ul class="my-2 grid grid-cols-3 grid-flow-row gap-2">
                    <li v-for="(slot, index) in slots" :key="index">
                      {{ formatHHmm(slot.datetime_start) }} -
                      {{ formatHHmm(slot.datetime_end) }}
                    </li>
                  </ul>
                </details>
              </template>
            </transition-group>

            <FormulateErrors />
            <FormulateInput
              type="submit"
              :disabled="isLoading || hasErrors"
              :help="hasErrors ? $t('form_not_valid') : ''"
              help-position="before"
              :input-class="['w-full lg:w-auto']"
            >
              <i
                :class="[
                  'far mr-2',
                  isLoading ? 'fa-spinner-third animate-spin' : 'fa-plus'
                ]"
              />
              {{ $t('add_reservation_block') }}
            </FormulateInput>
          </FormulateForm>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { createPropertyTimeSlot } from '@/services/calendarService';
import { formatHHmm, chunks } from '@/helpers';

export default {
  name: 'PropertyVisitCreateSlots',
  props: {
    visitDate: {
      type: Date,
      required: true
    }
  },
  data() {
    return {
      propertyId: this.$route.params.propertyId,
      slotForm: false,
      slotData: {},
      max_bookings: 1
    };
  },
  computed: {
    intervals() {
      let intervals = [];
      const start = this.slotData.datetime_start,
        end = this.slotData.datetime_end,
        diff = end - start;

      if (diff > 3_600_000) {
        intervals = [
          {
            value: '',
            label: this.$t('option_no_interval')
          },
          {
            value: 900_000,
            label: '15 ' + this.$t('minutes')
          },
          {
            value: 1_200_000,
            label: '20 ' + this.$t('minutes')
          },
          {
            value: 1_800_000,
            label: '30 ' + this.$t('minutes')
          },
          {
            value: 2_700_000,
            label: '45 ' + this.$t('minutes')
          },
          {
            value: 3_600_000,
            label: '60 ' + this.$t('minutes')
          }
        ];
      }

      return intervals;
    },
    slots() {
      let datetime_start =
          this.visitDate.getTime() + this.slotData.datetime_start,
        datetime_end = this.visitDate.getTime() + this.slotData.datetime_end,
        diff = datetime_end - datetime_start,
        interval = parseInt(this.slotData.interval);

      let slots = [];

      if (interval) {
        let i = Math.floor(diff / interval);

        for (i; i > 0; i--) {
          slots.push({
            max_bookings: this.max_bookings,
            property_id: this.propertyId,
            datetime_start: new Date(datetime_start),
            datetime_end: new Date(datetime_start + interval)
          });

          datetime_start = datetime_start + interval;
        }
      }

      return slots;
    }
  },
  methods: {
    formatHHmm,
    hours() {
      let hours = [];
      for (let i = 8; i < 24; i++) {
        hours.push(i);
      }
      return hours;
    },
    minutes() {
      let minutes = [];
      for (let i = 0; i < 60; i += 15) {
        minutes.push(i);
      }
      return minutes;
    },
    hideSlotForm() {
      this.slotForm = false;
      this.$emit('close');
    },
    showSlotForm() {
      this.slotForm = true;
    },
    submit(data) {
      const slots = [...this.slots];

      if (this.slots.length) {
        return chunks(
          slots,
          slot => {
            return createPropertyTimeSlot(this.propertyId, slot);
          },
          50
        ).then(() => {
          this.$store.dispatch('snackbar/show', {
            type: 'success',
            messageBold: 'Success!',
            message: this.$t('new_slot_added_plural')
          });

          this.hideSlotForm();
          this.$emit('updateTimeSlots');
        });
      } else {
        let datetime_start = new Date(
            this.visitDate.getTime() + data.datetime_start
          ),
          datetime_end = new Date(this.visitDate.getTime() + data.datetime_end);

        return createPropertyTimeSlot(this.propertyId, {
          datetime_start,
          datetime_end,
          max_bookings: this.max_bookings,
          property_id: this.propertyId
        })
          .then(() => {
            this.$store.dispatch('snackbar/show', {
              type: 'success',
              messageBold: 'Success!',
              message: this.$t('new_slot_added_singular')
            });

            this.hideSlotForm();
            this.$emit('updateTimeSlots');
          })
          .catch(error => {
            this.$formulate.handle(error, 'propertyCreateSlots');
          });
      }
    },
    validTime({ value }) {
      return value + this.visitDate.getTime() > new Date().getTime();
    },
    fiveMinDifference({ getFormValues }) {
      const values = getFormValues();
      return values.datetime_end - values.datetime_start > 300000;
    }
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{staticClass:"px-5 py-0.5 mt-8 block tg-mobile-button-small lg:tg-desktop-body text-white bg-brand-primary rounded-full shadow-card hover:bg-opacity-84",attrs:{"type":"button"},on:{"click":_vm.showSlotForm}},[_c('i',{staticClass:"far fa-plus mr-2"}),_vm._v(" "+_vm._s(_vm.$t('add_reservation_block'))+" ")]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.slotForm)?_c('div',{staticClass:"w-full h-full fixed inset-0 z-40 grid place-items-center bg-overlay overflow-auto"},[_c('div',{staticClass:"w-full max-w-lg bg-white rounded-lg cursor-auto"},[_c('div',{staticClass:"flex justify-between p-5 border-b border-gray-e9"},[_c('h4',{staticClass:"tg-mobile-header-4 lg:tg-desktop-header-4",domProps:{"textContent":_vm._s(_vm.$t('add_reservation_block'))}}),_c('button',{staticClass:"-m-4",attrs:{"type":"button"},on:{"click":_vm.hideSlotForm}},[_c('i',{staticClass:"p-4 far fa-times"})])]),_c('FormulateForm',{staticClass:"px-5",attrs:{"name":"propertyCreateSlots"},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var hasErrors = ref.hasErrors;
return [_c('div',{staticClass:"max-w-xs grid grid-cols-7 gap-5"},[_c('FormulateInput',{attrs:{"type":"timepicker","name":"datetime_start","label":_vm.$t('label_datetime_start'),"hours":_vm.hours(),"minutes":_vm.minutes(),"show-required-label":true,"validation":"bail|required|validTime|fiveMinDifference","validation-rules":{ validTime: _vm.validTime, fiveMinDifference: _vm.fiveMinDifference },"validation-messages":{
                validTime: _vm.$t('valid_time'),
                fiveMinDifference: _vm.$t('message_five_min_difference')
              },"outer-class":"mt-5 col-span-3"}}),_c('i',{staticClass:"mx-auto mt-14 far fa-arrow-right text-xs col-span-1"}),_c('FormulateInput',{attrs:{"type":"timepicker","name":"datetime_end","label":_vm.$t('label_datetime_end'),"hours":_vm.hours(),"minutes":_vm.minutes(),"show-required-label":true,"validation":"bail|required|validTime|fiveMinDifference","validation-rules":{ validTime: _vm.validTime, fiveMinDifference: _vm.fiveMinDifference },"validation-messages":{
                validTime: _vm.$t('valid_time'),
                fiveMinDifference: _vm.$t('message_five_min_difference')
              },"outer-class":"mt-5 col-span-3"}})],1),_c('FormulateInput',{attrs:{"type":"text","inputmode":"numeric","name":"max_bookings","value":"1","label":_vm.$t('max_bookings'),"show-required-label":true,"validation":"bail|required|number|min:1,value","validation-name":_vm.$t('this_field')},model:{value:(_vm.max_bookings),callback:function ($$v) {_vm.max_bookings=_vm._n($$v)},expression:"max_bookings"}}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.intervals.length)?_c('FormulateInput',{key:"interval_input",attrs:{"type":"select","name":"interval","label":_vm.$t('label_interval'),"placeholder":_vm.$t('placeholder_select_interval'),"options":_vm.intervals}}):_vm._e()],1),_c('transition-group',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.slotData.interval)?[_c('p',{key:"intervalSlotsInfo",staticClass:"tg-mobile-body-small md:tg-desktop-body"},[_vm._v(" "+_vm._s(_vm.$t('there_are'))+" "),_c('span',{staticClass:"text-brand-primary font-semibold"},[_vm._v(" "+_vm._s(_vm.slots.length)+" "+_vm._s(_vm.$t('appointment_blocks'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('from'))+" "),_c('span',{staticClass:"text-brand-primary font-semibold"},[_vm._v(" "+_vm._s(_vm.slotData.interval / 60000)+" "+_vm._s(_vm.$t('minutes'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('created'))+". ")]),_c('details',{key:"intervalSlots"},[_c('summary',{staticClass:"link font-semibold",domProps:{"textContent":_vm._s(_vm.$t('see_the_slots_to_be_created'))}}),_c('ul',{staticClass:"my-2 grid grid-cols-3 grid-flow-row gap-2"},_vm._l((_vm.slots),function(slot,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(_vm.formatHHmm(slot.datetime_start))+" - "+_vm._s(_vm.formatHHmm(slot.datetime_end))+" ")])}),0)])]:_vm._e()],2),_c('FormulateErrors'),_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading || hasErrors,"help":hasErrors ? _vm.$t('form_not_valid') : '',"help-position":"before","input-class":['w-full lg:w-auto']}},[_c('i',{class:[
                'far mr-2',
                isLoading ? 'fa-spinner-third animate-spin' : 'fa-plus'
              ]}),_vm._v(" "+_vm._s(_vm.$t('add_reservation_block'))+" ")])]}}],null,false,454985431),model:{value:(_vm.slotData),callback:function ($$v) {_vm.slotData=$$v},expression:"slotData"}})],1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }